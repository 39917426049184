import React, { useState, useEffect } from 'react';
import '../styles/Home.scss';
import Navigation from '../components/Navigation';
import HomeDashboardConts from '../components/HomeDashboardConts';
import { getTotalMagicbean } from '../api/MagicbeanAPI';
import Floating from '../components/Floating';
import loginSessionCheck from '../components/LoginSessionCheck';
import LandScapeModal from '../components/LandScapeModal';
import { useNavigate } from 'react-router-dom';
import { checkSession } from '../utils/SessionCheck';
import NewHeader from '../components/NewHeader';
import TcDownLoadBtnComponent from '../components/TcDownLoadBtnComponent';

const Home = () => {
    const navigate = useNavigate();
    const story4uURL = process.env.REACT_APP_STORY4U_URL;
    const story4uPort = process.env.REACT_APP_STORY4U_PORT;
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const IS_TEACHER = localStorage.getItem('isTeacher') === 'true';
    const LOGIN_TIME = localStorage.getItem('loginTime');
    const [logoImage, setLogoImage] = useState('');
    const [totalMagicbean, setTotalMagicbean] = useState(null);

    const logos = [
        '/images/logo-6.svg',
        '/images/logo-5.svg',
        '/images/logo-3.svg',
    ];

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * logos.length);
        setLogoImage(logos[randomIndex]);
    }, []);

    useEffect(() => {
        callgetTotalMagicbeanAPIFunc();
    }, []);

    const callgetTotalMagicbeanAPIFunc = async () => {
        const response = await getTotalMagicbean(USER_ID);

        if (response.success) {
            console.log(response.data);
            if (response.data) setTotalMagicbean(response.data);
            else setTotalMagicbean(0);
        } else {
            console.log(response.error.errMsg);
        }
    };

    return (
        <>
            <section className="contents home">
                <div className="right-contents home">
                    <NewHeader />
                    <div className="inner">
                        <div className="tit-container flex">
                            <h3 className="tit">
                                {IS_TEACHER
                                    ? '환영합니다. '
                                    : '수업을 열심히 듣고 있네요, '}
                                <em>{USER_NAME}</em> {IS_TEACHER && '선생'}님!
                            </h3>
                        </div>
                        <ul className="board-container">
                            <HomeDashboardConts />
                        </ul>
                    </div>
                </div>
                <Floating />
                {IS_TEACHER && <TcDownLoadBtnComponent />}
            </section>
            <LandScapeModal />
        </>
    );
};

export default loginSessionCheck(Home);
