import React from 'react';
import '../styles/ExpiredModal.scss';
import { useNavigate } from 'react-router-dom';

const ExpiredModal = () => {
    const navigation = useNavigate();

    return (
        <>
            <section className="expired-section">
                <div className="inner">
                    <figure>
                        <img
                            src={'/images/sleep.svg'}
                            alt="sleep"
                        />
                    </figure>
                    <div className="expired-container">
                        <p>로그인이 만료되었어요! 다시 로그인하러 갈까요?</p>
                        <button
                            onClick={() => {
                                navigation('/login');
                            }}
                        >
                            로그인 하러가기
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ExpiredModal;