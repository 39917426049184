import React, { useState } from 'react';
import '../styles/UsageBtnComponent.scss';
import UsageModal from './UsageModal';

const UsageBtnComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div
                className="usage-btn"
                onClick={handleButtonClick}
            >
                <figure>
                    {/* <img
                    src={'./images/announcement.svg'}
                    alt=""
                /> */}
                    <img
                        src={'./images/bean-white.svg'}
                        alt=""
                    />
                </figure>
                <span>
                    콩 소모 정책
                    <br /> 보기
                </span>
            </div>
            {isModalOpen && <UsageModal onClose={handleCloseModal} />}
        </>
    );
};

export default UsageBtnComponent;
