import React, { useEffect, useRef, useState } from 'react';
import '../styles/LongLoading.scss';
import Progress from './Progress';
import { getSchoolCreationListForLoading } from '../api/WorkAPI';
import OngoingModal from './OngoingModal';

const LongLoading = ({ type, selectedTitleKr }) => {
    const USER_ID = localStorage.getItem('userID');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndices, setCurrentIndices] = useState([0, 1, 2]);
    const [countDown, setCountDown] = useState(40);
    const [isPlaying, setIsPlaying] = useState(true);
    const [imageSources, setImageSources] = useState();

    // 2024-02-16 | OngoingModal state
    const [showOngoingModal, setShowOngoingModal] = useState(false);

    // 오디오 요소에 대한 참조 생성
    const audioRef = useRef(null);

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.volume = 0.2;
                audioRef.current.play().catch((error) => {
                    console.error('오디오 재생에 실패했습니다.', error);
                });
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        callCreationsAPIFunc();
    }, []);

    //NOTE: 로딩을 위한 학교 전체 그림 랜덤 5개 조회 API 호출 함수
    const callCreationsAPIFunc = () => {
        const response = getSchoolCreationListForLoading(USER_ID);

        response.then((res) => {
            if (res.success) {
                console.log('@@ datas =>', res.data);
                setImageSources(res.data);
            } else {
                console.log(res.error.errMsg);
                //NOTE: 2024-01-24 에러처리 필요 [수정]
            }
        });
    };

    const handleAudioPlay = () => {
        // audioRef.current.pause();
        //NOTE: 2024-01-23 수정 오디오 on/off 활성화
        setIsPlaying(!isPlaying);
    };

    // 갤러리 랜덤 이미지 출력
    useEffect(() => {
        if (!imageSources) return;
        const intervalId = setInterval(() => {
            setCurrentIndices((prevIndices) =>
                prevIndices.map((index) => (index + 3) % imageSources.length)
            );
        }, 3000);
        return () => clearInterval(intervalId);
    }, [imageSources]);

    // 시계 카운트다운
    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((prevCountDown) => prevCountDown - 1);
        }, 1000);

        if (countDown === 0) {
            clearInterval(interval);
            // 2024-02-16 | OngoingModal true
            setShowOngoingModal(true);
        }
        return () => clearInterval(interval);
    }, [countDown]);

    return (
        <>
            <section className="long-loading">
                <audio
                    ref={audioRef}
                    src="/audio/AIMagickid.mp3"
                />
                <div className="inner">
                    <figure className="lighting">
                        <img
                            src={'/images/lighting.svg'}
                            alt="lighting"
                        />
                    </figure>
                    <h2 className="loading-tit">
                        친구들의 멋진 작품을 감상하면서 기다려보세요!
                    </h2>
                    <Progress />
                    <div className="selected-kr">
                        내가 고른 선택지
                        <div className="selected-kr-container">
                            {selectedTitleKr.map((word, index) => (
                                <em
                                    key={index}
                                    className="keyword"
                                >
                                    {word}
                                </em>
                            ))}
                        </div>
                    </div>
                    <div className="clock">
                        <figure>
                            <img
                                src={'/images/clock.svg'}
                                alt="clock"
                            />
                        </figure>
                        <p
                            className={`count-down ${countDown <= 10 ? 'red' : ''
                                }`}
                        >
                            {countDown}
                        </p>
                    </div>
                    <button
                        className="music-play-btn"
                        onClick={handleAudioPlay}
                    >
                        <figure>
                            <img
                                src={
                                    isPlaying
                                        ? '/images/music-on.svg'
                                        : '/images/music-off.svg'
                                }
                                alt="music-play"
                            />
                        </figure>
                    </button>
                    <ul className="gallery-list">
                        {currentIndices.map((index, idx) => (
                            <li
                                className="item"
                                key={idx}
                            >
                                <div className="item-inner">
                                    <figure>
                                        {imageSources && (
                                            <img
                                                src={
                                                    imageSources[index].creation
                                                }
                                                alt={`Gallery ${idx + 1}`}
                                            />
                                        )}
                                    </figure>
                                    <div className="desc">
                                        {imageSources && (
                                            <>
                                                <span className="category">
                                                    {
                                                        imageSources[index]
                                                            .creation_title
                                                    }
                                                </span>
                                                <span>
                                                    [
                                                    <em>
                                                        {imageSources[index].name.length <= 3 ? imageSources[index].school_name : imageSources[index].name}
                                                    </em>

                                                    ]
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    {/* <figure className="people people-1">
                        <img
                            src={'/images/people-1.svg'}
                            alt="people"
                        />
                    </figure> */}
                    <figure className="people people-2">
                        <img
                            src={'/images/magic-boy.svg'}
                            alt="people"
                        />
                    </figure>
                    <figure className="people people-3">
                        <img
                            src={'/images/magic-girl.svg'}
                            alt="people"
                        />
                    </figure>
                </div>
                <div className="gallery-bottom"></div>
                {/* 2024-02-16 | OngoingModal */}
                {showOngoingModal && (
                    <OngoingModal onclose={() => setShowOngoingModal(false)} />
                )}
            </section>
        </>
    );
};

export default LongLoading;
