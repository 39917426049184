import React, { useEffect, useState } from 'react';
import '../styles/WorkSheetPage.scss';
import NewHeader from '../components/NewHeader';
import TcWorkSheetListComponent from '../components/TcWorkSheetListComponent';
import { useNavigate } from 'react-router-dom';
import { getTeachingPlanList } from '../api/TeacherAPI';
import TcLoadingSpinnerComponent from '../components/TcLoadingSpinnerComponent';
import TcFilterOptions from '../components/TcFilterOptions';
import UsageBtnComponent from '../components/UsageBtnComponent';

const WorkSheetPage = () => {
    const USER_ID = localStorage.getItem('userID');
    const navigate = useNavigate();
    const [teachingPlanList, setTeachingPlanList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedLevel, setSelectedLevel] = useState('all');
    const [selectedType, setSelectedType] = useState('all');
    const [isChanged, setIsChanged] = useState(false);

    const levelTranslations = {
        easy: '쉬움',
        common: '보통',
        hard: '어려움',
    };

    const typeTranslations = {
        life_edu: '생활교육',
        fun: '재미',
        ai_edu: 'AI교육',
        edu: '교육',
        all: '전체',
    };

    useEffect(() => {
        callGetteachingPlanListAPIFunc();
    }, []);

    const callGetteachingPlanListAPIFunc = async () => {
        setLoading(true);
        setError(null);

        const response = await getTeachingPlanList(USER_ID);

        if (response.success) {
            const translatedData = response.data.map((item) => ({
                ...item,
                hashtags_level: levelTranslations[item.hashtags_level],
                hashtags_type: typeTranslations[item.hashtags_type],
            }));
            setTeachingPlanList(translatedData);
            setLoading(false);
        } else {
            setError(response.error.errMsg);
            setLoading(false);
        }
    };

    const handleItemClick = (item) => {
        navigate(`/worksheetdetail/${item.plan_id}`, { state: { item } });
    };

    const handleFilterChange = (setter) => (value) => {
        setter(value);
        setIsChanged(value !== 0 || value !== 'all');
    };

    const handleResetFilters = () => {
        setSelectedMonth(0);
        setSelectedLevel('all');
        setSelectedType('all');
        setIsChanged(false);
    };

    const filteredData = teachingPlanList.filter((item) => {
        return (
            (selectedMonth === 0 || item.months === selectedMonth) &&
            (selectedLevel === 'all' ||
                item.hashtags_level === levelTranslations[selectedLevel]) &&
            (selectedType === 'all' ||
                item.hashtags_type === typeTranslations[selectedType])
        );
    });

    return (
        <section className="worksheet-section">
            <NewHeader />
            <div className="all-download-container">
                <div className="inner">
                    <div className="all-download-left">
                        <h3>교안</h3>
                        <p>
                            아래의 교안을 활용하여 학습 과정에 활용해 보세요.
                            <br />
                            학생들의 이해도를 높이는 <br /> 다양한 자료가
                            준비되어 있습니다.
                        </p>
                    </div>
                </div>
            </div>
            <div className="inner">
                <TcFilterOptions
                    selectedMonth={selectedMonth}
                    selectedLevel={selectedLevel}
                    selectedType={selectedType}
                    setSelectedMonth={handleFilterChange(setSelectedMonth)}
                    setSelectedLevel={handleFilterChange(setSelectedLevel)}
                    setSelectedType={handleFilterChange(setSelectedType)}
                    handleResetFilters={handleResetFilters}
                    isChanged={isChanged}
                />
                {loading && <TcLoadingSpinnerComponent />}
                {!loading && !error && (
                    <TcWorkSheetListComponent
                        worksheetData={filteredData}
                        onItemClick={handleItemClick}
                    />
                )}
            </div>
            <UsageBtnComponent />
        </section>
    );
};

export default WorkSheetPage;
