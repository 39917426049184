export const sessionTimeout = 1 * 60 * 60 * 1000; // 예: 1시간 
// export const sessionTimeout = 3000; // 테스트 세션 시간 1초

export const checkSession = () => {
    const loginTime = parseInt(localStorage.getItem('loginTime'), 10);
    const currentTime = new Date().getTime();

    if (currentTime - loginTime > sessionTimeout) {
        localStorage.removeItem('userID');
        localStorage.removeItem('userName');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('loginTime');

        return false; // 세션이 만료된 경우
    }
    return true; // 세션이 유효한 경우
};
