import React from 'react';
import '../styles/TcWorkSheetListComponent.scss';

const TcWorkSheetListComponent = ({ worksheetData, onItemClick }) => {
    console.log('worksheetData', worksheetData);

    const getLevelClass = (level) => {
        switch (level) {
            case '쉬움':
                return 'easy';
            case '보통':
                return 'normal';
            case '어려움':
                return 'hard';
            default:
                return '';
        }
    };

    return (
        <ul className="worksheet-list">
            {worksheetData.map((item) => (
                <li
                    key={item.plan_id}
                    onClick={() => onItemClick(item)}
                >
                    <figure>
                        {item.worksheet_main_url ? (
                            <img
                                src={item.worksheet_main_url}
                                alt={item.plan_title}
                            />
                        ) : (
                            <p>Loading...</p>
                        )}
                    </figure>
                    <h4 className="worksheet-title">{item.plan_title}</h4>
                    <div className="hashtags">
                        <p className={`level months`}>
                            #{item.months == 0 ? '전' : item.months}월
                        </p>
                        <p
                            className={`level ${getLevelClass(
                                item.hashtags_level
                            )}`}
                        >
                            #{item.hashtags_level}
                        </p>
                        <p>#{item.hashtags_type}</p>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default TcWorkSheetListComponent;
