import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkSession } from '../utils/SessionCheck';
import { getTotalMagicbean } from '../api/MagicbeanAPI';
import { download30PlanHWP } from '../api/TeacherAPI';
import '../styles/NewHeader.scss';

const NewHeader = () => {
    const navigate = useNavigate();
    const isTeacher = localStorage.getItem('isTeacher') === 'true';
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');
    const IS_TEACHER = localStorage.getItem('isTeacher') === 'true';
    const LOGIN_TIME = localStorage.getItem('loginTime');
    const story4uURL = process.env.REACT_APP_STORY4U_URL;
    const story4uPort = process.env.REACT_APP_STORY4U_PORT;
    const [isScalePageVisible, setIsScalePageVisible] = useState(false);
    const [logoImage, setLogoImage] = useState('');
    const [totalMagicbean, setTotalMagicbean] = useState(null);

    useEffect(() => {
        callgetTotalMagicbeanAPIFunc();
    }, []);

    const callgetTotalMagicbeanAPIFunc = async () => {
        const response = await getTotalMagicbean(USER_ID);

        if (response.success) {
            console.log(response.data);
            if (response.data) setTotalMagicbean(response.data);
            else setTotalMagicbean(0);
        } else {
            console.log(response.error.errMsg);
        }
    };

    const handleDownload30Plan = async () => {
        try {
            const result = await download30PlanHWP(USER_ID);
            if (result.success) {
                console.log(result.data);
                const blob = new Blob([result.data], {
                    type: 'application/octet-stream',
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `AI매직포유_지도계획_30차시.hwp`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    };

    const handleHistoryClick = () => {
        navigate(`/history`);
    };
    const handleArtWorkClick = () => {
        navigate('/work/my');
    };
    const handlePoemClick = () => {
        // navigate('/promptCategory/writing');
        navigate('/whub/writing');
    };

    const handleDrawClick = () => {
        navigate('/dhub/drawing');
    };

    const handleworksheetClick = () => {
        navigate('/worksheet');
    };

    const handlePoemClickWrapper = () => {
        handlePoemClick();
        toggleScalePage();
    };

    const handleDrawClickWrapper = () => {
        handleDrawClick();
        toggleScalePage();
    };

    const handleArtWorkClickWrapper = () => {
        handleArtWorkClick();
        toggleScalePage();
    };

    const handleStory4UClick = () => {
        if (checkSession()) {
            const params = {
                userID: USER_ID,
                userName: USER_NAME,
                loginTime: LOGIN_TIME,
                page: 'selecting',
            };
            const query = new URLSearchParams(params).toString();
            window.open(
                `${story4uURL}:${story4uPort}/check?${query}`,
                '_blank',
                'noopener,noreferrer'
            );
        } else {
            navigate('/logout');
        }
    };

    const toggleScalePage = () => {
        setIsScalePageVisible(!isScalePageVisible);
    };

    const handleTabChange = (tab_name) => {
        handleTabClick(tab_name);
        if (isScalePageVisible) {
            toggleScalePage();
        }
    };

    const handleTabClick = (tab_name) => {
        if (tab_name === 'myHome') {
            navigate('/home');
        } else if (tab_name === 'myClass') {
            navigate('/class');
        } else if (tab_name === 'allcontest') {
            navigate('/work/school');
        } else if (tab_name === 'community') {
            navigate('/community');
        } else if (tab_name === 'student') {
            navigate('/teacher-main');
        } else if (tab_name === 'storyland') {
            if (checkSession()) {
                const params = {
                    userID: USER_ID,
                    userName: USER_NAME,
                    loginTime: LOGIN_TIME,
                    page: 'storyland',
                };
                const query = new URLSearchParams(params).toString();
                window.open(
                    `${story4uURL}:${story4uPort}/check?${query}`,
                    '_blank',
                    'noopener,noreferrer'
                );
            } else {
                navigate('/logout');
            }
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('isTeacher');
        localStorage.removeItem('userID');
        localStorage.removeItem('userName');
        navigate('/');
    };

    const logos = [
        '/images/logo-6.svg',
        '/images/logo-5.svg',
        '/images/logo-3.svg',
    ];

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * logos.length);
        setLogoImage(logos[randomIndex]);
    }, []);

    return (
        <div className="new-header">
            <div className="new-header-left">
                <h1 className="logo">
                    <img
                        src={logoImage}
                        alt="logo"
                    />
                </h1>
                <div className="dropdown">
                    <button
                        className="dropbtn"
                        onClick={() => handleTabChange('myHome')}
                    >
                        홈
                    </button>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">나의 공간</button>
                    <div className="dropdown-content">
                        <a onClick={() => handleTabChange('myHome')}>
                            나의 공간
                        </a>
                        <a
                            href="#sub1"
                            onClick={handleArtWorkClick}
                        >
                            나의 작품 모아보기
                        </a>
                        <a
                            href="#sub2"
                            onClick={handleHistoryClick}
                        >
                            나의 콩 사용내역
                        </a>
                    </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">나의 수업</button>
                    <div className="dropdown-content">
                        <a onClick={() => handleTabClick('myClass')}>
                            나의 수업
                        </a>
                        <a
                            href="#sub1"
                            onClick={handlePoemClick}
                        >
                            글 만들기
                        </a>
                        <a
                            href="#sub2"
                            onClick={handleDrawClick}
                        >
                            그림 그리기
                        </a>
                        <a
                            href="#sub3"
                            onClick={handleStory4UClick}
                        >
                            동화 만들기
                        </a>
                    </div>
                </div>
                <div className="dropdown">
                    <button
                        className="dropbtn"
                        onClick={() => handleTabClick('allcontest')}
                    >
                        우리학교
                    </button>
                </div>
                <div className="dropdown">
                    <button
                        className="dropbtn"
                        onClick={() => handleTabClick('storyland')}
                    >
                        동화보기
                    </button>
                </div>
                {/* 여기 선생님 로그인 */}
                {isTeacher && (
                    <div className="dropdown">
                        <button
                            className="dropbtn"
                            onClick={() => handleTabChange('student')}
                        >
                            학생현황
                        </button>
                    </div>
                )}
                {isTeacher && (
                    <div className="dropdown">
                        <button className="dropbtn">수업자료</button>
                        <div className="dropdown-content">
                            <a onClick={handleDownload30Plan}>
                                지도계획 다운로드
                            </a>
                            <a
                                href="#sub1"
                                onClick={handleworksheetClick}
                            >
                                교안 보러가기
                            </a>
                        </div>
                    </div>
                )}
            </div>
            <div className="new-header-right">
                <div
                    className="remain-beans"
                    onClick={handleHistoryClick}
                >
                    <figure>
                        <img
                            src={'/images/bean.svg'}
                            alt="매직콩"
                        />
                    </figure>
                    <p>
                        잔여 콩 :{' '}
                        <em className="blue-bold">{totalMagicbean}</em> 콩
                    </p>
                </div>
                <div
                    className="logout-btn"
                    onClick={handleLogout}
                >
                    <span>로그아웃</span>
                    <figure>
                        <img
                            src="/images/logout.svg"
                            alt="로그아웃"
                        />
                    </figure>
                </div>
                {/* 여기부터 */}
                <div
                    id="floatingButton"
                    className="ham-menu"
                    onClick={toggleScalePage}
                >
                    <span>
                        <img
                            src={'/images/nav/bx-menu.svg'}
                            alt=""
                        />
                    </span>
                    <span>MENU</span>
                </div>
                <div
                    id="scalePage"
                    className={`scale-page-content ${
                        isScalePageVisible ? 'visible' : ''
                    }`}
                >
                    <div
                        class="close-tab"
                        onClick={toggleScalePage}
                    >
                        <img
                            src={'/images/nav/close.svg'}
                            alt=""
                        />
                    </div>
                    <ul className="tab-nav-list">
                        <li className="my">
                            <div className="tab-left">
                                <figure>
                                    <img
                                        src={'/images/nav/tab-my.svg'}
                                        alt=""
                                    />
                                </figure>
                                <span>나의 공간</span>
                            </div>
                            <div className="tab-right">
                                <div
                                    className="nav-con"
                                    onClick={() => handleTabChange('myHome')}
                                >
                                    나의공간
                                </div>
                                <div
                                    className="nav-con"
                                    onClick={handleArtWorkClickWrapper}
                                >
                                    나의 작품 모아보기
                                </div>
                                <div
                                    className="nav-con"
                                    onClick={handleHistoryClick}
                                >
                                    나의 콩 사용내역
                                </div>
                            </div>
                        </li>
                        <li className="class">
                            <div className="tab-left">
                                <figure>
                                    <img
                                        src={'/images/nav/tab-class.svg'}
                                        alt=""
                                    />
                                </figure>
                                <span>나의 수업</span>
                            </div>
                            <div className="tab-right">
                                <div
                                    className="nav-con"
                                    onClick={() => handleTabChange('myClass')}
                                >
                                    나의 수업
                                </div>
                                <div
                                    className="nav-con"
                                    onClick={handlePoemClickWrapper}
                                >
                                    글 만들기
                                </div>
                                <div
                                    className="nav-con"
                                    onClick={handleDrawClickWrapper}
                                >
                                    그림 그리기
                                </div>
                                <div
                                    className="nav-con"
                                    onClick={handleStory4UClick}
                                >
                                    동화 만들기
                                </div>
                            </div>
                        </li>
                        <li className="school">
                            <div className="tab-left">
                                <figure>
                                    <img
                                        src={'/images/nav/tab-school.svg'}
                                        alt=""
                                    />
                                </figure>
                                <span>우리 학교</span>
                            </div>
                            <div className="tab-right">
                                <div
                                    className="nav-con"
                                    onClick={() =>
                                        handleTabChange('allcontest')
                                    }
                                >
                                    전체 작품 모아보기
                                </div>
                            </div>
                        </li>
                        <li className="storyland">
                            <div className="tab-left">
                                <figure>
                                    <img
                                        src={'/images/nav/tab-book.svg'}
                                        alt=""
                                    />
                                </figure>
                                <span>동화 보기</span>
                            </div>
                            <div className="tab-right">
                                <div
                                    className="nav-con"
                                    onClick={() => handleTabChange('storyland')}
                                >
                                    스토리랜드 동화보기
                                </div>
                            </div>
                        </li>
                        {isTeacher && (
                            <li className="student">
                                <div className="tab-left">
                                    <figure>
                                        <img
                                            src={'/images/nav/nav-teacher.svg'}
                                            alt=""
                                        />
                                    </figure>
                                    <span>학생현황</span>
                                </div>
                                <div className="tab-right">
                                    <div
                                        className="nav-con"
                                        onClick={() =>
                                            handleTabChange('student')
                                        }
                                    >
                                        우리반 현황
                                    </div>
                                    <div
                                        className="nav-con"
                                        onClick={handleDownload30Plan}
                                    >
                                        지도계획 다운로드
                                    </div>
                                    <div
                                        className="nav-con"
                                        onClick={handleworksheetClick}
                                    >
                                        교안 보러가기
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                    <button
                        className="tab-logout"
                        onClick={handleLogout}
                    >
                        <span>로그아웃</span>
                        <figure>
                            <img
                                src={'/images/logout.svg'}
                                alt="logout"
                            />
                        </figure>
                    </button>
                </div>
                <div
                    className={`menu-mask ${
                        isScalePageVisible ? 'visible' : ''
                    }`}
                ></div>
                {/* 여기까지 모바일 메뉴 */}
            </div>
        </div>
    );
};

export default NewHeader;
