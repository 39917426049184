import React from 'react';
import '../styles/OngoingModal.scss';

const OngoingModal = ({ onclose }) => {
    return (
        <section className="ongoing-section">
            <div className="inner">
                <figure>
                    <img src={'/images/making_2.svg'} alt="" />
                </figure>
                <div className="txt-container">
                    <p>
                        AI마법사가 지금 너무 바쁜가봐요. 조금만 더 기다려주세요.
                    </p>
                    <button onClick={onclose}>확인</button>
                </div>
            </div>
        </section>
    );
};

export default OngoingModal;
