import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import '../styles/Main.scss';
import { useNavigate } from 'react-router-dom';
import LandScapeModal from '../components/LandScapeModal';
import Header from '../components/Header';
import Footer from '../components/Footer';
import InqueryButton from '../components/InqueryButton';
import { getPDFDownloadURL } from '../api/SupportAPI';

const Main = () => {
    const { t } = useTranslation('pages/mainpage');
    const [pdfURL, setPDFURL] = useState('');

    const navigate = useNavigate();

    const goDashBoard = () => {
        navigate('/login');
    };

    const [logoImage, setLogoImage] = useState();
    const logos = [
        '/images/logo-1.svg',
        '/images/logo-2.svg',
        '/images/logo-4.svg',
    ];
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * logos.length);
        setLogoImage(logos[randomIndex]);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        callPdfLinkDownloadAPI();
    }, []);

    const callPdfLinkDownloadAPI = async () => {
        const result = await getPDFDownloadURL();
        if (result.success) {
            console.log(result.data);
            setPDFURL(result.data);
        } else {
            console.log('err');
        }
    };

    return (
        <>
            <div id="wrap">
                <Header />
                <section className="section-1">
                    <div className="inner flex">
                        <div className="left">
                            <div className="left-main-txt">
                                <figure className="main-explain-txt">
                                    <img
                                        src={'/images/main-text.svg'}
                                        alt=""
                                    />
                                </figure>
                                <figure className="main-explain-txt-center">
                                    <img
                                        src={'/images/main-text-center.svg'}
                                        alt=""
                                    />
                                </figure>
                                <figure className="main-white-logo">
                                    <img
                                        src={'/images/main/logo-white.svg'}
                                        alt="logo-white"
                                        className="white-logo"
                                    />
                                    <img
                                        src={'/images/main/main-1-circle.svg'}
                                        alt="circle"
                                        className="circle"
                                    />
                                </figure>
                            </div>
                            <div className="btn-box">
                                <div
                                    className="btn start-btn"
                                    onClick={goDashBoard}
                                >
                                    {/* {t('main.loginBtn')} */}
                                    시작하기
                                </div>
                                <div className="btn requst-btn">도입문의</div>
                            </div>
                        </div>
                        <figure className="right right-robot">
                            <img
                                src={'/images/char-1.svg'}
                                alt="main-robot"
                            />
                        </figure>
                    </div>
                    <a
                        href="#video-section"
                        class="scroll"
                    >
                        둘러보기<span></span>
                    </a>
                </section>
                <section
                    className="video-section"
                    id="video-section"
                >
                    <div className="inner">
                        <div className="tit-container">
                            <p className="sub-tit-en">News report</p>
                            <h2>AI매직포유를 미디어에서 만나보세요!</h2>
                        </div>

                        <div className="video-wrap">
                            <iframe
                                src="https://www.youtube.com/embed/JUatH29nTe4"
                                title="지역 청년들이 개발한 AI교재가 초등 교실에서 240811"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                            ></iframe>
                        </div>
                        {/* NOTE: 2024-08-22 모든 학교가 수업 시작하는 9월 중순 이후에 오픈 */}
                        {/* <h3>
                            올바른 AI 미래를 향한 첫걸음, 수 많은 학교에서
                            함께하는 중입니다.
                        </h3>
                        <p className="join-text">
                            더 많은 아이들에게 꿈을 심어줄 수 있도록, 동행해
                            주세요.
                        </p> */}
                    </div>
                </section>
                {/* NOTE: 2024-08-22 모든 학교가 수업 시작하는 9월 중순 이후에 오픈 */}
                {/* <section
                    className="promotion-section"
                    id="promotion-section"
                >
                    <div className="logo-slide-container">
                        <div className="slide slide-1"></div>
                        <div className="slide slide-2"></div>
                    </div>
                </section> */}
                <section
                    className="section-2"
                    id="section-2"
                >
                    <div className="inner flex">
                        <div className="left">
                            <p className="sub-tit-en">Education</p>
                            <h2>
                                {/* <Trans i18nKey="main.section1.titleMain" ns="pages/mainpage" /> */}
                                AI 첫만남은 <br />
                                AI매직포유와 함께
                            </h2>
                            <p className="sub-tit-kr">
                                실습 중심 학습으로 다양한 체험과
                                <br /> 윤리적 사고 배양까지!
                            </p>
                            <div>
                                <a
                                    href={pdfURL}
                                    className="btn request-btn"
                                    target="_blank"
                                >
                                    <span>소개서 다운로드</span>
                                    <figure>
                                        <img
                                            src={'/images/main/Download.svg'}
                                            alt="다운로드 아이콘"
                                        />
                                    </figure>
                                </a>
                            </div>
                        </div>
                        <div className="right">
                            <div className="right-conts-box">
                                <div className="icon-box">
                                    <figure>
                                        <img
                                            src={'/images/main/laptop.svg'}
                                            alt="laptop"
                                        />
                                    </figure>
                                </div>
                                <div className="text-box">
                                    <h3 className="text-tit">실습중심 학습</h3>
                                    <p>
                                        직접 AI를 활용한 실습을 <br />
                                        통하여 학습하는 체험형 교육
                                    </p>
                                </div>
                            </div>
                            <div className="right-conts-box">
                                <div className="icon-box">
                                    <figure>
                                        <img
                                            src={'/images/main/file-edit.svg'}
                                            alt="laptop"
                                        />
                                    </figure>
                                </div>
                                <div className="text-box">
                                    <h3 className="text-tit">
                                        다양한 학습자료
                                    </h3>
                                    <p>
                                        AI 기술에 대한 다양한 <br />
                                        학습 자료와 실습 예제 제공
                                    </p>
                                </div>
                            </div>
                            <div className="right-conts-box">
                                <div className="icon-box">
                                    <figure>
                                        <img
                                            src={'/images/main/hexagon.svg'}
                                            alt="laptop"
                                        />
                                    </figure>
                                </div>
                                <div className="text-box">
                                    <h3 className="text-tit">
                                        윤리적 사고 배양
                                    </h3>
                                    <p>
                                        AI 기술의 윤리적 사용에 <br />
                                        대한 중요성을 함께 교육
                                    </p>
                                </div>
                            </div>
                            <div className="right-conts-box">
                                <div className="icon-box">
                                    <figure>
                                        <img
                                            src={'/images/main/layers.svg'}
                                            alt="laptop"
                                        />
                                    </figure>
                                </div>
                                <div className="text-box">
                                    <h3 className="text-tit">
                                        차세대 기술교육
                                    </h3>
                                    <p>
                                        생성형 AI 활용 능력 향상 <br />
                                        교육으로 미래형 인재를 육성
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-3">
                    <div className="inner">
                        <div className="tit-container">
                            <p className="sub-tit-en">Motivation</p>
                            <h2>
                                <em className="interesting">흥미롭게</em> 배우는
                                AI 교육
                            </h2>
                            <p className="sub-tit-kr">
                                학습동기를 부여하며 흥미를 유발하는 즐거운 AI
                                클래스
                            </p>
                        </div>
                        <ul className="motivation-list">
                            <li>
                                <figure>
                                    <img
                                        src={'/images/main/main-3-1.svg'}
                                        alt="main-3-1"
                                    />
                                </figure>
                                <dl>
                                    <dt>
                                        학습동기
                                        <br />
                                        부여
                                    </dt>
                                    <dd>
                                        {/* <Trans i18nKey="main.section2.box1.description" components={{ span: <span className='bold' /> }} ns="pages/mainpage" /> */}
                                        <span className="bold">
                                            AI를 활용한 그림, 글 생성 등을
                                            <br />
                                            통해 창작의 즐거움
                                        </span>
                                        과 학습동기 부여
                                    </dd>
                                </dl>
                            </li>
                            <li>
                                <figure>
                                    <img
                                        src={'/images/main/main-3-2.svg'}
                                        alt="main-3-2"
                                    />
                                </figure>
                                <dl>
                                    <dt>
                                        교육과
                                        <br />
                                        재미의 조화
                                    </dt>
                                    <dd>
                                        <span className="bold">
                                            기존의 학습 방식을 넘어서는
                                            <br />
                                            경험
                                        </span>
                                        으로 교육적 가치와 재미를 제공
                                    </dd>
                                </dl>
                            </li>
                            <li>
                                <figure>
                                    <img
                                        src={'/images/main/main-3-3.svg'}
                                        alt="main-3-3"
                                    />
                                </figure>
                                <dl>
                                    <dt>
                                        몰입도
                                        <br />
                                        증가
                                    </dt>
                                    <dd>
                                        <span className="bold">
                                            다양한 체험을 통한 학습
                                        </span>
                                        으로
                                        <br />
                                        학생들의 몰입도를 높임
                                    </dd>
                                </dl>
                            </li>
                        </ul>
                        <div
                            className="btn request-btn"
                            onClick={() => {
                                navigate('/function');
                            }}
                        >
                            주요기능 보기
                        </div>
                    </div>
                </section>
                <section className="section-4">
                    <div className="inner flex">
                        <div className="left">
                            <p className="sub-tit-en">Discussion</p>
                            <h2>
                                <em>토의</em>로
                                <br />
                                생각의 확장까지
                            </h2>
                            <p className="sub-tit-kr">
                                단순 AI 제작 실습이 아닌,
                                <br /> 토론을 통한 생각의 확장까지!
                            </p>

                            <div
                                className="btn request-btn"
                                onClick={() => {
                                    navigate('/contact');
                                }}
                            >
                                도입문의
                            </div>
                        </div>
                        <ul className="right">
                            <li>
                                <figure>
                                    <img
                                        src={'/images/main/Chatbubble.svg'}
                                        alt="말풍선"
                                    />
                                </figure>
                                <div className="text">
                                    <h3 className="chat-tit">사고 촉진</h3>
                                    <p>
                                        AI가 창작한 결과물에 대한
                                        <em className="bold">
                                            평가를 통해 비판적 사고 촉진
                                        </em>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img
                                        src={
                                            '/images/main/Chatbubble-right.svg'
                                        }
                                        alt="말풍선"
                                    />
                                </figure>
                                <div className="text">
                                    <h3 className="chat-tit">생각의 확장</h3>
                                    <p>
                                        AI가 만들어낸 결과물에 대한
                                        <em className="bold">
                                            토의를 통해 다양한 관점을 이해
                                        </em>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img
                                        src={'/images/main/Chatbubble.svg'}
                                        alt="말풍선"
                                    />
                                </figure>
                                <div className="text">
                                    <h3 className="chat-tit">이해 능력 향상</h3>
                                    <p>
                                        토론을 통해
                                        <em className="bold">
                                            원인과 결과의 관계를 파악하고,
                                            논리적 사고력
                                        </em>
                                        을 배양
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <Footer />
                <InqueryButton />
            </div>
            <LandScapeModal />
        </>
    );
};

export default Main;
