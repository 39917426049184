import React from 'react';
import '../styles/Footer.scss';

const Footer = () => {
    const handleGoChainTree = () => {
        window.open(`https://chaintree.biz`, '_blank', 'noopener,noreferrer');
    };
    return (
        <footer id="footer">
            <div className="inner">
                <div className="footer-tit-area">
                    <h1 className="footer-logo">
                        <img
                            src={'/images/footer-logo.svg'}
                            alt="footer-logo"
                        />
                    </h1>
                    <p className="area-desc">
                        AI매직포유는 AI와 친구가 될 수 있도록 즐겁고 안전하게
                        생성형 AI를 체험하는 교육 플랫폼입니다. AI매직포유로
                        아이들은 자기 표현 능력을 키우고 AI와 상호작용하며
                        창의력을 향상시킬 수 있습니다.
                        <br />
                        <br />
                        아이들을 위해 체인트리(주)는 재미, 교육, 생활에 맞는
                        다채로운 유형의 콘텐츠를 제작합니다.
                    </p>
                </div>
                <div className="footer-main-con">
                    <div className="left">
                        <dl>
                            <dt>사업자명</dt>
                            <dd>체인트리 주식회사</dd>
                        </dl>
                        <dl>
                            <dt>Address.</dt>
                            <dd>
                                울산광역시 중구 종가로 362-11
                                울산과학기술진흥센터 3층 305호
                            </dd>
                        </dl>
                        <dl>
                            <dt>사업자등록번호</dt>
                            <dd>526-88-02959</dd>
                        </dl>
                        <dl>
                            <dt>E-Mail.</dt>
                            <dd>chaintree0818@gmail.com</dd>
                        </dl>
                    </div>
                </div>

                <p
                    className="website"
                    onClick={handleGoChainTree}
                >
                    www.chaintree.biz
                </p>
                <br />
                <p className="copy-right">
                    © Copyright ChainTree Inc. All Rights Reserved
                </p>
            </div>
        </footer>
    );
};

export default Footer;
