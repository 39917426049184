import React, { useState, useEffect, useRef } from 'react';
import '../styles/TrQuestionRelay.scss';
import TrCompleteComponent from '../components/TrCompleteComponent';
import { useNavigate } from 'react-router-dom';
import { callChatGPT } from '../api/ClassAPI';
import { ERROR } from '../utils/Constant';
import ErrorModal from '../components/ErrorModal';
import Loading from '../components/Loading';
import AnswerWrongModal from '../components/AnswerWrongModal';
import AnswerCorrectModal from '../components/AnswerCorrectModal';

const TrQuestionRelay = ({
    onNext,
    onPrevious,
    title,
    training_id,
    selection_list,
    selected_magickid,
    onEnd,
}) => {
    const USER_ID = localStorage.getItem('userID');
    const USER_NAME = localStorage.getItem('userName');

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestions, setCurrentQuestions] = useState([]);
    const [selectionList, setSelectionList] = useState({
        selection: selection_list.selection || [],
        image: selection_list.image || [],
    });
    const [answer, setAnswer] = useState('');
    const [answerImage, setAnswerImage] = useState('');

    const [attemptCount, setAttemptCount] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [confirmSubmit, setConfirmSubmit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [failed, setFailed] = useState(false);
    const [actions, setActions] = useState([]);
    const [feedback, setFeedback] = useState(null);
    const [hint, setHint] = useState(null);
    const navigation = useNavigate();
    const bubbleAreaRef = useRef(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [showWrongModal, setShowWrongModal] = useState(false);
    const [showCorrectModal, setShowCorrectModal] = useState(false);

    const MAX_ATTEMPTS = 5;
    const remainingAttempts = MAX_ATTEMPTS - attemptCount;

    console.log('answer: ', answer);
    console.log('answerImage: ', answerImage);

    const aimagickid = {
        arial: '/images/bubble-arial.svg',
        nikki: '/images/bubble-niki.svg',
    };

    useEffect(() => {
        if (completed) {
            const audio = new Audio('/audio/winning-quiz.mp3');
            audio.play();
        }
    }, [completed]);

    useEffect(() => {
        if (failed) {
            const audio = new Audio('/audio/failure-quiz.mp3');
            audio.play();
        }
    }, [failed]);

    useEffect(() => {
        let audio;

        if (showLoading) {
            audio = new Audio('/audio/AIMagic4u-loading-quiz.mp3');
            audio.play();
        } else if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
            }
        };
    }, [showLoading]);


    useEffect(() => {
        if (selection_list.selection.length > 0) {
            randomSetAnswer();
        }
    }, [selection_list]);

    useEffect(() => {
        if (answer !== '') {
            executeAI();
        }
    }, [answer]);

    const randomSetAnswer = async () => {
        const randomIndex = Math.floor(
            Math.random() * selection_list.selection.length
        );
        setAnswer(selection_list.selection[randomIndex]);
        setAnswerImage(selection_list.image[randomIndex]);
    }

    const executeAI = async () => {
        setShowLoading(true);

        const prompt_content = [USER_NAME, answer];
        let body = {
            prompt: prompt_content,
        };
        console.log('@@prompt => ', body);

        const response = await callChatGPT(
            USER_ID,
            'writing',
            training_id,
            body
        );
        if (response.success) {
            console.log('callChatGPT => ', response.data);
            const result = response.data.replace(/"/g, '');
            setCurrentQuestions((prevQuestions) => [...prevQuestions, result]);
            setFeedback(null);

            setShowLoading(false);
        } else {
            console.log(response.error.errMsg);
            console.log(response.error);
            setShowLoading(false);
            if (response.error.errCode === 'Magicbean_ERR_00') {
                setShowErrorModal(true);
                setErrorMessage(response.error.errMsg);
            } else {
                setShowErrorModal(true);
                setErrorMessage(ERROR.busy);
            }
        }
    };

    const handleOptionClick = (index) => {
        setSelectedOption({
            image: selectionList.image[index],
            text: selectionList.selection[index],
        });
        setConfirmSubmit(true);
    };

    const handleSubmitAnswer = (confirm) => {
        if (confirm) {
            setAttemptCount((prevCount) => {
                const newCount = prevCount + 1;
                console.log('New attemptCount: ', newCount);

                if (newCount >= MAX_ATTEMPTS) {
                    setFailed(true);
                }
                return newCount;
            });

            const newActions = [...actions];
            newActions.push({ type: 'selection', content: selectedOption });

            if (selectedOption.text === answer) {
                setFeedback('정답이에요! 대단한걸요?');
                setShowCorrectModal(true);
                setCompleted(true);

                setTimeout(() => {
                    setShowCorrectModal(false);
                }, 3000); // 3초 후에 모달 닫기
            } else {
                if (attemptCount < MAX_ATTEMPTS - 1) {
                    // 5번째 시도 전까지만
                    setFeedback('땡!! 다시 글만들기 AI 한테 물어볼게요.');
                    setShowWrongModal(true);
                    const audio = new Audio('/audio/beep-quiz.mp3');
                    audio.play();

                    setActions(newActions);
                    setSelectedOption(null);
                    setConfirmSubmit(false);

                    setTimeout(() => {
                        executeAI();
                    }, 2000);
                }
            }
        } else {
            setSelectedOption(null);
            setConfirmSubmit(false);
        }
    };

    const handleRetry = () => {
        randomSetAnswer();
        setAttemptCount(0);
        setCurrentQuestionIndex(0);
        setSelectedOption(null);
        setConfirmSubmit(false);
        setFailed(false);
        setFeedback(null);
        setCompleted(false);
        setActions([]);
        setCurrentQuestions([]);
        setShowWrongModal(false);
        setShowCorrectModal(false);
    };

    const handleNextExperience = () => {
        navigation('/whub/writing#sub1');
    };

    useEffect(() => {
        if (bubbleAreaRef.current) {
            bubbleAreaRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [
        actions,
        feedback,
        currentQuestions,
        selectedOption,
        confirmSubmit,
        failed,
    ]);

    return (
        <>
            {showLoading && (
                <Loading
                    flag="generative"
                    selected_magickid={selected_magickid}
                ></Loading>
            )}
            {showErrorModal && (
                <ErrorModal
                    error_message={errorMessage}
                    onClose={() => setShowErrorModal(false)}
                ></ErrorModal>
            )}
            {showWrongModal && (
                <AnswerWrongModal onClose={() => setShowWrongModal(false)} />
            )}
            {showCorrectModal && <AnswerCorrectModal />}
            <div className="tr-bg relay">
                <div className="inner">
                    <div className="remaining-attempts">
                        남은 횟수: {remainingAttempts}
                    </div>
                    <div className="tr-box relay">
                        <div className="complete-txt-container">
                            <p className="title-text">
                                AI가 내는 퀴즈를 맞춰봐요!
                            </p>
                            <div className="divider-line"></div>
                        </div>

                        <div
                            className="bubble-area"
                            ref={bubbleAreaRef}
                        >
                            {currentQuestions.map((question, index) => (
                                <div
                                    className="question-bubble-area"
                                    key={index}
                                >
                                    <figure>
                                        <img
                                            src={aimagickid[selected_magickid]}
                                            alt="캐릭터"
                                        />
                                    </figure>
                                    <div className="question-bubble">
                                        {question}
                                    </div>
                                </div>
                            ))}
                            {actions.map((action, index) => (
                                <div
                                    key={index}
                                    className="user-reply new-reply"
                                >
                                    {action.type === 'selection' && (
                                        <>
                                            <div className="selected-bubble">
                                                <span className="selected-option">
                                                    {action.content.text}
                                                </span>
                                                를 선택했습니다.
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                            {feedback && !failed && (
                                <div className="question-bubble-area">
                                    <figure>
                                        <img
                                            src={aimagickid[selected_magickid]}
                                            alt="캐릭터"
                                        />
                                    </figure>
                                    <div
                                        className="question-bubble"
                                        style={{ color: '#e97c2c' }}
                                    >
                                        {feedback}
                                    </div>
                                </div>
                            )}
                            {attemptCount != 0 &&
                                !failed &&
                                !completed &&
                                !feedback && (
                                    <div className="question-bubble-area">
                                        <figure>
                                            <img
                                                src={
                                                    aimagickid[
                                                    selected_magickid
                                                    ]
                                                }
                                                alt="캐릭터"
                                            />
                                        </figure>
                                        <div className="question-bubble">
                                            {
                                                currentQuestions[
                                                currentQuestions.length - 1
                                                ]
                                            }
                                        </div>
                                    </div>
                                )}
                            {!confirmSubmit &&
                                !completed &&
                                !failed &&
                                !feedback && (
                                    <div className="user-reply options">
                                        <p>
                                            아래의 버튼들 중에서 답변을
                                            골라볼까요?
                                        </p>
                                        <div className="option-btns">
                                            {selectionList.selection.map(
                                                (option, index) => (
                                                    <button
                                                        key={index}
                                                        onClick={() =>
                                                            handleOptionClick(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        {option}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            {selectedOption && !failed && (
                                <div className="user-reply new-reply">
                                    <div className="selected-bubble">
                                        <span className="selected-option">
                                            {selectedOption.text}
                                        </span>
                                        를 선택했습니다.
                                    </div>
                                </div>
                            )}
                            {confirmSubmit && !failed && (
                                <div className="user-reply new-reply">
                                    <div className="selected-bubble">
                                        이대로 정답을 제출할까요?
                                    </div>
                                    <button
                                        onClick={() => handleSubmitAnswer(true)}
                                        className="okay-btn"
                                    >
                                        예
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleSubmitAnswer(false)
                                        }
                                        className="no-btn"
                                    >
                                        아니오
                                    </button>
                                </div>
                            )}
                            {failed && (
                                <>
                                    <div className="question-bubble-area">
                                        <figure>
                                            <img
                                                src={
                                                    aimagickid[
                                                    selected_magickid
                                                    ]
                                                }
                                                alt="캐릭터"
                                            />
                                        </figure>
                                        <div className="question-bubble new-reply">
                                            <div>
                                                아쉽게도 문제를 맞추지
                                                못했어요...정답은
                                                <span className="selected-option">
                                                    {answer}
                                                </span>
                                                입니다!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-container">
                                        <button
                                            onClick={handleRetry}
                                            className="retry-quiz"
                                        >
                                            다시 도전하기
                                        </button>
                                        <button
                                            onClick={handleNextExperience}
                                            className="another-experience"
                                        >
                                            다른 체험하기
                                        </button>
                                    </div>
                                </>
                            )}
                            {completed && (
                                <>
                                    <div className="question-bubble-area">
                                        <figure>
                                            <img
                                                src={
                                                    aimagickid[
                                                    selected_magickid
                                                    ]
                                                }
                                                alt="캐릭터"
                                            />
                                        </figure>
                                        <div className="question-bubble new-reply">
                                            <div>
                                                정답입니다!!! 대단한걸?! 정답
                                                그림을 함께 보아요.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="question-bubble new-reply">
                                        <figure>
                                            <img
                                                src={answerImage}
                                                alt="선택된 이미지"
                                            />
                                        </figure>
                                    </div>
                                    <div className="button-container">
                                        <button
                                            onClick={handleRetry}
                                            className="retry-quiz"
                                        >
                                            <figure>
                                                <img
                                                    src={'/images/remake.svg'}
                                                    alt=""
                                                />
                                            </figure>
                                            <span>다시 퀴즈 맞추기</span>
                                        </button>
                                        <button
                                            onClick={() =>
                                                onEnd(
                                                    currentQuestions[
                                                    currentQuestions.length -
                                                    1
                                                    ]
                                                )
                                            }
                                            className="another-experience"
                                        >
                                            <figure>
                                                <img
                                                    src={'/images/close.png'}
                                                    alt=""
                                                />
                                            </figure>
                                            <span>끝내기</span>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrQuestionRelay;