import './App.css';
import React, { useEffect, useState } from 'react';
import './i18n';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GlobalStyles } from './styled';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import Class from './pages/Class';
import Community from './pages/Community';
import Work from './pages/Work';
import AllContest from './pages/AllContest';
import IndividualContest from './pages/IndividualContest';
import ClassDetail from './pages/ClassDetail';
import DebateModal from './components/DiscussionModal';
import PromptCategory from './pages/PromptCategory';
import CompleteModal from './components/CompleteModal';
import Main from './pages/Main';
import Loading from './components/Loading';
import { ClassProvider } from './context/ClassContext';
import { WorkProvider } from './context/WorkContext';
import Login from './pages/Login';
import NickName from './pages/NickName';
import TeacherLogin from './pages/TeacherLogin';
import TeacherMain from './pages/TeacerMain';
import Membership from './pages/Membership';
import MembershipComplete from './components/MembershipComplete';
import ExpiredModal from './components/ExpiredModal';
import History from './pages/History';
import KeyFunctions from './pages/KeyFunctions';
import Price from './pages/Price';
import Information from './pages/Information';
import Contact from './pages/Contact';
import Heart from './components/Heart';
import DrawingTraining from './pages/DrawingTraining';
import DrawingHub from './pages/DrawingHub';
import WritingHub from './pages/WritingHub';
import WritingTraining from './pages/WritingTraining';
import FavoriteTraining from './pages/FavoriteTraining';
import Guide from './pages/Guide';
import TestPage from './pages/TestPage';
import ViewAllTrainings from './pages/ViewAllTrainings';
import LongLoading from './components/LongLoading';
import WorkSheetPage from './pages/WorkSheetPage';
import TcWorkSheetDetailComponent from './components/TcWorkSheetDetailComponent';

function App() {
    const [hearts, setHearts] = useState([]);

    const handleHeartClick = (event) => {
        const x = event.pageX;
        const y = event.pageY;
        const now = Date.now();
        setHearts((prevHearts) => [
            ...prevHearts,
            { id: now, left: x, top: y },
        ]);
        setTimeout(() => {
            setHearts((current_hearts) =>
                current_hearts.filter((heart) => heart.id !== now)
            );
        }, 500);
    };

    useEffect(() => {
        document.addEventListener('click', handleHeartClick);

        // //NOTE: 우클릭방지 처리
        // const handleRightClick = (event) => {
        //     event.preventDefault();
        // };

        // document.addEventListener('contextmenu', handleRightClick);

        // // 컴포넌트 언마운트 시 이벤트 리스너 제거
        // return () => {
        //     document.removeEventListener('contextmenu', handleRightClick);
        // };
    }, []);

    return (
        <BrowserRouter>
            <GlobalStyles />
            {hearts.map(({ id, left, top }) => (
                <Heart
                    key={id}
                    left={left}
                    top={top}
                />
            ))}
            <Routes>
                <Route
                    path="/"
                    element={<Main />}
                />
                <Route
                    path="/home"
                    element={<Home />}
                />
                <Route
                    path="/class"
                    element={
                        <ClassProvider>
                            <Class />
                        </ClassProvider>
                    }
                />
                <Route
                    path="/classdetail/:lessonId"
                    element={
                        <ClassProvider>
                            <ClassDetail />
                        </ClassProvider>
                    }
                />
                <Route
                    path="/promptcategory/:trainingType"
                    element={
                        <ClassProvider>
                            <PromptCategory />
                        </ClassProvider>
                    }
                />
                <Route
                    path="/dhub/:trainingType"
                    element={<DrawingHub />}
                />
                <Route
                    path="/whub/:trainingType"
                    element={<WritingHub />}
                />
                <Route
                    path="/drawingtraining/:trainingType/:trainingId"
                    element={
                        <ClassProvider>
                            <DrawingTraining />
                        </ClassProvider>
                    }
                />
                {/* merry route 테스트 */}
                <Route
                    path="/writingtraining/:trainingType/:trainingId"
                    element={
                        <ClassProvider>
                            <WritingTraining />
                        </ClassProvider>
                    }
                />
                {/* merry route 테스트 */}
                <Route
                    path="/fav-conts"
                    element={<FavoriteTraining />}
                />
                <Route
                    path="/allcontest"
                    element={<AllContest />}
                />
                <Route
                    path="/community"
                    element={<Community />}
                />
                <Route
                    path="/navigation"
                    element={<Navigation />}
                />
                <Route
                    path="/work/:pageType"
                    // element={<Work />}
                    element={
                        <WorkProvider>
                            <Work />
                        </WorkProvider>
                    }
                />
                <Route
                    path="/individualContest"
                    element={<IndividualContest />}
                />

                <Route
                    path="/login"
                    element={<Login />}
                />
                <Route
                    path="/nickname"
                    element={<NickName />}
                />
                <Route
                    path="/teacher-login"
                    element={<TeacherLogin />}
                />
                <Route
                    path="/teacher-main"
                    element={<TeacherMain />}
                />
                <Route
                    path="/worksheet"
                    element={<WorkSheetPage />}
                />
                <Route
                    path="/worksheetdetail/:id"
                    element={<TcWorkSheetDetailComponent />}
                />
                <Route
                    path="/membership"
                    element={<Membership />}
                />
                <Route
                    path="/commodal"
                    element={<MembershipComplete />}
                />

                <Route
                    path="/debate"
                    element={<DebateModal />}
                />

                <Route
                    path="/complete"
                    element={<CompleteModal />}
                />
                <Route
                    path="/loading"
                    element={<Loading />}
                />

                <Route
                    path="/logout"
                    element={<ExpiredModal />}
                />
                <Route
                    path="/history"
                    element={<History />}
                />
                <Route
                    path="/information"
                    element={<Information />}
                />
                <Route
                    path="/function"
                    element={<KeyFunctions />}
                />
                <Route
                    path="/price"
                    element={<Price />}
                />
                <Route
                    path="/contact"
                    element={<Contact />}
                />
                <Route
                    path="/guide"
                    element={<Guide />}
                />
                <Route
                    path="/test"
                    element={<TestPage />}
                />
                <Route
                    path="/allview"
                    element={<ViewAllTrainings />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
