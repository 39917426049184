import React, { useState, useEffect, useRef } from 'react';
import '../styles/TrDrawingResultComponent.scss';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import Print from './Print';
import ViewAllModal from './ViewAllModal';
import BigSizeModal from './BigSizeModal';
import OptionChangeModal from './OptionChangeModal';

const TrDrawingResultComponent = ({
    clearSelections,
    onRegenerate,
    onNext,
    onPrevious,
    selectedKRList,
    resultContent,
    resultContentList,
    onWordClick,
    selected_magickid,
}) => {
    const isTeacher = localStorage.getItem('isTeacher') === 'true';
    const [showViewAllModal, setShowViewAllModal] = useState(false);
    const [finalPrompt, setFinalPrompt] = useState('');
    const [selectedButton, setSelectedButton] = useState({});
    const [showBigModal, setShowBigModal] = useState(false);
    const [showOptionChangeModal, setShowOptionChangeModal] = useState(false);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);

    const printRef = useRef();

    useEffect(() => {
        let updatedPrompt = '';
        for (let i = 0; i < selectedKRList.length; i++) {
            if (i === selectedKRList.length - 1)
                updatedPrompt += selectedKRList[i];
            else updatedPrompt += selectedKRList[i] + ', ';
        }
        // 최종 문자열을 state 변수에 저장
        setFinalPrompt(updatedPrompt);
    }, []);

    const handleClose = () => {
        setShowViewAllModal(false);
    };

    const handleChangeContent = (selected) => {
        resultContent = selected;
    };

    const handleNextClick = () => {
        onNext(resultContent); // 결과 이미지와 함께 onNext 호출
    };

    const handleOptionChangeConfirm = () => {
        setShowOptionChangeModal(false);
        onWordClick(selectedOptionIndex);
    };

    const handleOptionChangeCancel = () => {
        setShowOptionChangeModal(false);
    };

    const handleWordClickWithConfirmation = (index) => {
        setSelectedOptionIndex(index);
        setShowOptionChangeModal(true);
    };

    return (
        <div className="tr-bg complete">
            <div className="inner">
                <div className="tr-box complete">
                    <figure className="complete-image">
                        <img
                            src={resultContent}
                            alt=""
                            className="result-image-conts"
                        />
                        <div
                            className="big-size-btn"
                            onClick={() => setShowBigModal(true)}
                        >
                            <figure>
                                <img
                                    src={'/images/big-white.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>크게보기</span>
                        </div>
                    </figure>
                    <div className="complete-txt-container">
                        <h3>그림이 완성되었어요!</h3>
                        <div className="divider"></div>
                        <p className="complete-txt-all">
                            <div className="keyword-container">
                                {selectedKRList.map((word, index) => (
                                    <span
                                        key={index}
                                        className="keyword"
                                        onClick={() =>
                                            handleWordClickWithConfirmation(
                                                index
                                            )
                                        }
                                    >
                                        {word}
                                    </span>
                                ))}
                            </div>
                            <br />
                            이렇게 선택해서 AI가 그려줬어.
                            <br />
                            <br />
                            그림이 마음에 들어?
                        </p>
                        <div className="complete-button-container">
                            <div className="remake-btn-wrap">
                                <button onClick={clearSelections}>
                                    <figure>
                                        <img
                                            src={'/images/remake.svg'}
                                            alt=""
                                        />
                                    </figure>
                                    <span>처음부터 다시 만들기</span>
                                </button>
                                <button onClick={onRegenerate}>
                                    <figure>
                                        <img
                                            src={'/images/remake.svg'}
                                            alt=""
                                        />
                                    </figure>
                                    <span>이대로 다시 만들기</span>
                                </button>
                            </div>
                            {isTeacher && (
                                <>
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="print-experience">
                                                <figure>
                                                    <img
                                                        src={
                                                            '/images/print-icon.svg'
                                                        }
                                                        alt=""
                                                    />
                                                </figure>
                                                프린트하기
                                            </button>
                                        )}
                                        content={() => printRef.current}
                                    />
                                    <div style={{ display: 'none' }}>
                                        <Print
                                            ref={printRef}
                                            type="drawing"
                                            image={resultContent}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    {showViewAllModal && (
                        <ViewAllModal
                            type="drawing"
                            resultList={[...resultContentList].reverse()}
                            onSelectContent={handleChangeContent}
                            onClose={handleClose}
                        />
                    )}
                </div>
                <div className="step-btns">
                    <div
                        className="button button-prev"
                        onClick={onPrevious}
                    >
                        ◀ 이전
                    </div>
                    <div
                        className="button button-next"
                        // onClick={() => onNext(selectedButton)}
                        onClick={handleNextClick}
                    >
                        다음 ▶
                    </div>
                </div>
            </div>
            {showBigModal && (
                <BigSizeModal
                    resultContent={resultContent}
                    onClose={() => setShowBigModal(false)}
                />
            )}
            <OptionChangeModal
                show={showOptionChangeModal}
                onConfirm={handleOptionChangeConfirm}
                onCancel={handleOptionChangeCancel}
                optionText={selectedKRList[selectedOptionIndex]}
                selected_magickid={selected_magickid}
            />
        </div>
    );
};

export default TrDrawingResultComponent;
