import React from 'react';
import '../styles/SchoolTitle.scss';

const SchoolTitle = ({ school_name }) => {
    return (
        <div className="in-class">
            <em>{school_name}</em>
            수업진행 중입니다.
        </div>
    );
};

export default SchoolTitle;
